import { Injectable, Injector } from '@angular/core';

import { LoggerService } from '../../../utilities/logger/logger.service';

import * as SockJS from 'sockjs-client';
// import * as Stomp from 'stompjs';
import { TnLoaderService } from '../../../utilities/tn-loader/tn-loader.service';
import * as _ from 'lodash';
import { AccountManagerService } from '../account/account-manager.service';
import { WebclientLoginService } from '../../../login/webclient-login.service';
import { WebclientService } from '../../webclient.service';
import { Router } from '@angular/router';
import { LocalStorageManagerService } from '../../../utilities/local-storage/local-storage-manager.service';
import { TimestampService } from '../../../utilities/timestamp/timestamp.service';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { TeamNoteLocalStorageKeyConstants } from '../../../constants/local-storage-key.constant';
import { MessageTypeConstant } from '../../../constants/message-type.constant';
import { PresenceTypeConstant } from '../../../constants/presence-type.constant';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';
import { Client } from '@stomp/stompjs';

@Injectable()
export class SocketService {

  // Socket & Client object
  private _socket;
  private _client;

  // Connect params
  private _login: string;           // user_id
  private _passcode: string;        // session_token
  private _host: string = '/';

  // Controls
  _isConnected: boolean = false;
  _numOfInitialConnectAttempt: number = 0;
  _isConnecting: boolean = false;
  reconnectTimer: any = null;
  abnormalReconnectTimer: any = null;

  _messageChannelSub: any = null;
  _presenceChannelSub: any = null;

  // Times
  _lastActiveTime: number = 0;
  _loginTime: number = 0;       // TODO: check if still needed
  _disconnectTime: number = 0;

  // For Reconnecting
  _isNeedReconnect: boolean = false;
  _isOfflineMsgGot: boolean = false;
  _isOfflinePresenceGot: boolean = false;

  // Subscription Queue name
  isSubscribedMessage: boolean = false;
  messageQueueName: string = "";
  messageTestSubInterval: any;

  isSubscribedPresence: boolean = false;
  presenceQueueName: string = "";
  presenceTestSubInterval: any;

  // Callback functions
  private _callbacks: Object = {};

  constructor(
    private _loggerService: LoggerService,
    private _tnLoaderService: TnLoaderService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _timestampService: TimestampService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _accountManagerService: AccountManagerService,
    private _tnNotificationService: TnNotificationService,
    private injector: Injector,
  ) { }

  getStompHost(isWebSocket: boolean) {
    this._loggerService.debug("Getting stomp host...");
    let protocol = "";
    if (isWebSocket) {
      protocol = this._teamnoteConfigService.config.HOST.WEB_CLIENT_SSL ? 'wss://' : 'ws://';
    } else {
      protocol = this._teamnoteConfigService.config.HOST.WEB_CLIENT_SSL ? 'https://' : 'http://';
    }
    let host = '';
    if (this._teamnoteConfigService.config.HOST.API_HOST) {
      host = this._teamnoteConfigService.config.HOST.API_HOST.split('//')[1].split(':')[0];
    } else {
      host = window.location.hostname;
    }
    let port = (this._teamnoteConfigService.config.HOST.WEB_CLIENT_PORT ? (':' + this._teamnoteConfigService.config.HOST.WEB_CLIENT_PORT) : '');

    let stompHostPath = protocol + host + port + '/stomp';
    if (isWebSocket) {
      stompHostPath += "/websocket";
    }
    return stompHostPath;
  }

  connectToWebSocket(userId, sessionToken) {
    if (this._isConnecting) {
      this._loggerService.debug('WebSocket is connecting...')
      return
    }
    this._isConnecting = true
    if (this._socket) {
      this.debug('EXISTING SOCKET');
    }

    let isWebSocket = this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_USE_WEBSOCKET;
    if (this._accountManagerService.getLoginFieldByFieldName('amqp_use_sockjs') == 1) {
      isWebSocket = false
    }

    this.stompConnect(userId, sessionToken, isWebSocket);
  }

  stompConnect(userId, passcode, isWebSocket): void {
    this._loggerService.debug("Connecting to web socket with userId [[[ {userId} ]]] and passcode [[[ {passcode} ]]]".replace("{userId}", userId).replace("{passcode}", passcode));

    this._numOfInitialConnectAttempt++;

    let stompHost = this.getStompHost(isWebSocket);
    this._loggerService.debug("Got stomp host: " + stompHost);

    this._loggerService.debug("Try to init WebSocket instance...");

    this._login = userId;
    this._passcode = passcode;

    let headers = {
      login: this._login,
      passcode: this._passcode,
      host: this._host
    };

    // create stomp instance
    this._client = new Client({
      brokerURL: stompHost,
      connectHeaders: headers,
      debug: (debugStr) => this.debug(debugStr),
      reconnectDelay: 0,
      heartbeatIncoming: 55000,
      heartbeatOutgoing: 55000,
      discardWebsocketOnCommFailure: true
    });

    if (!isWebSocket) {
      // creates a new SockJS instance to be used for each (re)connect
      this._client.webSocketFactory = () => new SockJS(stompHost);
    }

    this._client.onWebSocketClose = (frame) => this.onWebSocketClose(frame);
    this._client.onWebSocketError = (frame) => this.onWebSocketError(frame);
    this._client.onDisconnect = (frame) => this.onDisconnect(frame);
    // this._client.onChangeState = (frame) => this.onChangeState(frame);

    // TODO: add back hash_password part?
    if (this._isNeedReconnect) {
      this._tnLoaderService.showSpinner('LOADING.RECONNECTING');
    } else {
      this._tnLoaderService.showSpinner('LOADING.CONNECTING');
    }

    // onnectCallback
    this._client.onConnect = (frame) => this.connectCallback(frame);

    // errorCallback
    this._client.onStompError = (frame) => this.onStompError(frame); 

    // console.log('this._client', this._client);

    this._client.activate();
  }

  getClient(): boolean {
    return this._client
  }

  getClientConnection(): boolean {
    return this._client.connected
  }

  debug(string) {
    // console.log('[STOMP]', string)

    if (string != ">>> PING" && string != "<<< PONG") {
      this._loggerService.debug('[STOMP] >>> ' + string);
    }

    // if (string != ">>> PING") {
    //   this._loggerService.info(string);
    //   this._loggerService.debug(">>>>>> [ Background info from WebSocket ] >>>>>>: " + string);

    //   // console.log('websocket debug callback info >>>', string)
    //   if (string.startsWith('did not receive server activity for the last')) {
    //     // disconnect manually
    //     this._loggerService.debug("Disconnecting web socket, after detected [did not receive...]");
    //     this.disconnectSocket(true);
    //     this.setIsNeedReconnectTrue();
    //     this.setDisconnectTime();

    //     this._tnNotificationService.showCustomWarningByTranslateKey('GENERAL.WEBSOCKET.OFFLINE');

    //     // try to reconnect the ws
    //     this._loggerService.debug("Reconnecting web socket after detected [did not receive...]");
    //     clearTimeout(this.abnormalReconnectTimer);
    //     this._loggerService.debug("Try to reconnect WebSocket after 1s..., after detected [did not receive...]")
    //     this.abnormalReconnectTimer = setTimeout(() => { 
    //       this._tnLoaderService.showSpinner('LOADING.RECONNECTING');
    //       this._loggerService.debug("Try to reconnect, connect web socket again, after detected [did not receive...]");
    //       this.connectToWebSocket(this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID), this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN));
    //     }, 1000)
    //   }
    // }
  }

  onStompError(frame) {
    this._loggerService.debug("[onStompError]" + frame.headers['message' || 'none']);
    // console.log('Broker reported error: ' + frame.headers['message']);
    // console.log('Additional details: ' + frame.body);

    // this._loggerService.debug('Broker reported error: ' + frame?.headers['message'] || 'none');
    this._loggerService.debug('Additional details: ' + frame?.body || 'none');
    // console.error("[onStompError]" + frame?.body || 'none');

    const _webclientService = this.injector.get<WebclientService>(WebclientService); // solve the circular dependency
    if (!_webclientService.getIsConnected()) {
      this._loggerService.debug("User logout, no need to reconnect");
      return;
    }
    // try to reconnect/loggout according to the error frame
    this.errorCallback(frame)
  }

  onWebSocketClose(error) {
    this._loggerService.debug("[onWebSocketClose] " + error?.reason || 'None');
    // console.log('[onWebSocketClose @@@]', error);

    const _webclientService = this.injector.get<WebclientService>(WebclientService); // solve the circular dependency
    if (!_webclientService.getIsConnected()) {
      this._loggerService.debug("User logout, no need to reconnect");
      return;
    }

    // try to reconnect/loggout according to the error frame
    this.errorCallback(error)
  }

  onWebSocketError(error) {
    this._loggerService.debug("[onWebSocketError] " + error?.reason || 'None');
    // console.log('[onWebSocketError ###]', error);

    const _webclientService = this.injector.get<WebclientService>(WebclientService); // solve the circular dependency
    if (!_webclientService.getIsConnected()) {
      this._loggerService.debug("User logout, no need to reconnect");
      return;
    }
    // try to reconnect/loggout according to the error frame
    this.errorCallback(error)
  }

  onDisconnect(error) {
    this._loggerService.debug("[onDisconnect] " + error?.body || 'none');
    // console.log('[onDisconnect🔥]', error);

    this.disconnectCallback();
  }

  onChangeState(states) {
    console.log('onChangeState', states);
  }

  connectCallback(frame) {
    this._isConnected = true;
    this._isConnecting = false
    this._loginTime = _.now() / 1000;
    this._loggerService.debug("Socket connect success, set loginTime: " + this._loginTime);

    this.customConnectCallback(frame);
    this.subscribeToChannels();
  }
  customConnectCallback(frame) {
    // redirect to webclient services' onConnectCallback
  }

  errorCallback(error) {
    // console.log('Socket errorCallback:', error);
    this._loggerService.debug("Socket errorCallback: " + error);
    this._isConnecting = false

    if (!this._isConnected) {
      if (
        this._numOfInitialConnectAttempt > 3 && 
        this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_USE_WEBSOCKET && 
        _.includes([null, 0], this._accountManagerService.getLoginFieldByFieldName('amqp_use_sockjs')) &&
        this._teamnoteConfigService.config.WEBCLIENT.GENERAL.IS_ALLOW_SOCKJS_FALLBACK &&
        !this._isNeedReconnect
      ) {
        this._loggerService.debug("Try to fallback to SockJS...");
        // Only try to fallback to SockJS if it was configured to use websocket, and fallback is allowed, after 3 failed connection attempts
        this.stompConnect(this._login, this._passcode, false);
        return;
      }
    }

    this.customErrorCallback(error);
  }

  customErrorCallback(error) {
    // redirect to webclient services' socketErrorCallback
  }

  checkIfNeedToReconnect() {
    this._loggerService.debug('>>> check user has logged In: [[[ {value} ]]]'.replace('{value}', this._accountManagerService.isLoggedIn.toString()));
    // check if trying ws reconnection after user assessing refused
    if (!this._accountManagerService.isLoggedIn) {
      return
    }

    // console.log("_isNeedReconnect?", this._isNeedReconnect);
    if (!this._client || !this._isConnected) { 
      if (this._isNeedReconnect) {
        this._tnLoaderService.showSpinner('LOADING.RECONNECTING');

        clearTimeout(this.reconnectTimer);
        // console.log('try to reconnect after 1s ==================>')
        this._loggerService.debug("try to reconnect WebSocket after 3s...")
        this.reconnectTimer = setTimeout(() => { 
          this._loggerService.debug("To simulate reconnect, connect web socket again");
          this.connectToWebSocket(this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.USER_ID), this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.SESSION_TOKEN));
        }, 1000)
      }
    }
  }

  subscribeToChannels() {
    this._loggerService.debug('Subscribing Channels...');
    this.isSubscribedMessage = false;
    this.isSubscribedPresence = false;
    let now = new Date().getTime();
    this.presenceQueueName = ["stomp-subscription-web-presence", this._login, now].join("-");

    this.messageQueueName = ["stomp-subscription-web-message", this._login, now].join("-");

    // Message channel
    this._messageChannelSub = this._client.subscribe(
      '/exchange/' + this._login + '.message/0', 
      (frame) => this.receiveMessage(frame), 
      {
        'user-id': this._login,
        ack: 'auto',
        'x-queue-name': this.messageQueueName
      }
    );
    // Presence channel
    this._presenceChannelSub = this._client.subscribe(
      '/exchange/' + this._login + '.presence/0', 
      (frame) => this.receivePresence(frame), 
      {
        'user-id': this._login,
        ack: 'auto',
        'x-queue-name': this.presenceQueueName
      }
    );

    this.testSubscription();
  }

  testSubscription(): void {
    // Test subscription after 2 seconds
    setTimeout(() => {
      this._loggerService.debug("Try to check subscription...");
      this.testMessageSubscription();
      this.testPresenceSubscription();
    }, 2000);
  }

  testMessageSubscription(): void {
    if (!this.isSubscribedMessage) {
      this._loggerService.debug("Check message subscription for the first time.");
      this.checkMessageSubscription();
      this._loggerService.debug("Setting up message sub checking interval");
      this.messageTestSubInterval = setInterval(() => {
        this.checkMessageSubscription();
      }, 5000);
    } else {
      this._loggerService.debug("Message subscription success. no need to check");
    }
  }

  checkMessageSubscription(): void {
    if (this.isSubscribedMessage) {
      this._loggerService.debug("Message queue subscribed. Clearing interval...");
      clearInterval(this.messageTestSubInterval);
      return;
    }

    let routingKey = ".message/0";
    let correlationId = "TEST_MESSAGE_SUB_" + new Date().getTime();
    let headers = {
      'reply-to': this.messageQueueName,
      'correlation-id': correlationId
    };
    let body = '';
    let callback = () => {
      this._loggerService.debug("Received message test sub response");
      this.postMessageSubscribe();
      this.checkMessageSubscription();
    };

    this._loggerService.debug("Sending message test sub message");
    this.sendMessage(
      routingKey,
      headers,
      body,
      correlationId,
      callback
    );
  }

  postMessageSubscribe(): void {
    if (this.isSubscribedMessage) {
      return;
    }
    this.isSubscribedMessage = true;
    let queueBindMessage = {
      headers: {
        temp_qname: this.messageQueueName
      }
    };
    this._loggerService.debug('[messageQueueName in postMessageSubscribe] >>>' + this.messageQueueName)
    this._loggerService.debug("Pass a custom message response to set queue name binding...");
    this.receiveMessage(queueBindMessage, true);
  }

  receiveMessage(frame, isBinding?) {
    this.setLastActiveTime(frame.headers["timestamp"]);

    // Check queue bind here.
    if (frame.headers.type == MessageTypeConstant.QUEUE_BIND) {
      this._loggerService.debug("Handle queue bind message response");
      this.postMessageSubscribe();
      return;
    }

    let correlation_id = frame.headers["correlation-id"] ? frame.headers["correlation-id"] : frame.headers["correlation_id"];
    // Always perform standard unknown callback first
    this.unknownMessageCallback(frame, isBinding);
    if (correlation_id) {
      correlation_id = correlation_id.replace('\\c', ':');
      let callback = this._callbacks[correlation_id];
      if (callback) {
        callback(frame, isBinding);
        delete this._callbacks[correlation_id];
      } else {
        // this.unknownMessageCallback(frame);
      }
    } else {
      // this.unknownMessageCallback(frame);
    }
  }

  unknownMessageCallback(frame, isBinding?) {
    // redirect to general message callback in dataManager
  }

  testPresenceSubscription(): void {
    if (!this.isSubscribedPresence) {
      this._loggerService.debug("Check presence subscription for the first time.");
      this.checkPresenceSubscription();
      this._loggerService.debug("Setting up presence sub checking interval");
      this.presenceTestSubInterval = setInterval(() => {
        this.checkPresenceSubscription();
      }, 5000);
    } else {
      this._loggerService.debug("Presence subscription success. no need to check");
    }
  }

  checkPresenceSubscription(): void {
    if (this.isSubscribedPresence) {
      this._loggerService.debug("Presence queue subscribed. Clearing interval...");
      clearInterval(this.presenceTestSubInterval);
      return;
    }

    let routingKey = ".presence/0";
    let correlationId = "TEST_PRESENCE_SUB_" + new Date().getTime();
    let headers = {
      'reply-to': this.presenceQueueName,
      'correlation-id': correlationId
    };
    let body = '';
    let callback = () => {
      this._loggerService.debug("Received presence test sub response");
      this.postPresenceSubscribe();
      this.checkPresenceSubscription();
    };

    this._loggerService.debug("Sending presence test sub message");
    this.sendMessage(
      routingKey,
      headers,
      body,
      correlationId,
      callback
    );
  }

  postPresenceSubscribe(): void {
    if (this.isSubscribedPresence) {
      return;
    }
    this.isSubscribedPresence = true;
    let queueBindPresence = {
      headers: {
        temp_qname: this.presenceQueueName
      }
    };
    this._loggerService.debug("Pass a custom presence response to set queue name binding...");
    this.receivePresence(queueBindPresence, true);
  }

  receivePresence(frame, isBinding?) {
    this.setLastActiveTime(frame.headers["timestamp"]);

    if (frame.headers.type == PresenceTypeConstant.QUEUE_BIND) {
      this._loggerService.debug("Handle queue bind presence response");
      this.postPresenceSubscribe();
      return;
    }

    let correlation_id = frame.headers["correlation-id"] ? frame.headers["correlation-id"] : frame.headers["correlation_id"];
    // Always perform standard unknown callback first
    this.unknownPresenceCallback(frame, isBinding);
    if (correlation_id) {
      correlation_id = correlation_id.replace('\\c', ':');
      let callback = this._callbacks[correlation_id];
      if (callback) {
        callback(frame, isBinding);
        delete this._callbacks[correlation_id];
      } else {
        // this.unknownPresenceCallback(frame);
      }
    } else {
      // this.unknownPresenceCallback(frame);
    }
  }

  unknownPresenceCallback(frame, isBinding?) {
    // redirect to general presence callback in dataManager
  }

  addCallbackFunction(correlationId: string, callback: Function): void {
    if (correlationId && callback) {
      this._callbacks[correlationId] = callback;
    }
  }

  sendMessage(routingKey, headers, body, correlationId?, callback?) {
    if (!this._client || !this._client.connected) {
      // this._tnNotificationService.showCustomWarningByTranslateKey('GENERAL.WEBSOCKET.DISCONNECTED');
      return;
    }
    this.addCallbackFunction(correlationId, callback);
    // all headers should have user-id
    headers['user-id'] = this._login;
    // add destination prefix 
    let destination = '/exchange/' + this._login + routingKey;
    // this._client.send(destination, headers, body);
    this._client.publish({ destination: destination, body: body, headers: headers});
  }

  setLastActiveTime(time) {
    // if needed to reconnect & load offline -> dont update last active time
    if (!this._isNeedReconnect) {
      this._lastActiveTime = this._lastActiveTime < time ? time : this._lastActiveTime;
    }
    this._loggerService.debug("Set last active time: " + this._lastActiveTime);

    // TODO:
    if (typeof time != 'undefined' && this._loginTime == 0) {
      this._loginTime = time;
    }
  }

  disconnectSocket(isDisconnectedByServer?: boolean) {
    this._loggerService.debug("Disconnecting web socket...");
    this._login = '';
    this._loginTime = 0;    // TODO: check if really need?
    
    this.isSubscribedMessage = false;
    this.messageQueueName = "";
    clearInterval(this.messageTestSubInterval);

    this.isSubscribedPresence = false;
    this.presenceQueueName = "";
    clearInterval(this.presenceTestSubInterval);

    if (this._client) {
      // this._client.disconnect(() => this.disconnectCallback(isDisconnectedByServer));

      // handle ws disconnection manually
      if (this._client.connected) {
        if (this._messageChannelSub) {
          this._messageChannelSub.unsubscribe();
        }
        
        if (this._presenceChannelSub) {
          this._presenceChannelSub.unsubscribe();
        }

        // console.log('force disconnect ws, this._client.deactivate is called');
        // this._client.disconnect(() => this.disconnectCallback(isDisconnectedByServer));
        this._client.deactivate();
      } else {
        this.disconnectCallback(isDisconnectedByServer)
      }
    }
  }
  disconnectCallback(isDisconnectedByServer?: boolean) {
    if (this._isConnected) {
      this._numOfInitialConnectAttempt = 0;
    }

    this._messageChannelSub = null;
    this._presenceChannelSub = null;

    this._isConnected = false;

    this._socket = null;
    this._client = null;
    this._loggerService.debug('Web socket is disconnected successfully');
    // if (isDisconnectedByServer) {
    //   this.setIsNeedReconnectTrue();
    // }
  }

  // Handle reconnect
  setIsNeedReconnectTrue() {
    this._loggerService.debug("Set isNeedReconnect to true");
    this._isNeedReconnect = true;
    this._isOfflineMsgGot = false;
    this._isOfflinePresenceGot = false;
  }
  realSetIsNeedReconnectFalse() {
    this._isNeedReconnect = false;
    this._tnLoaderService.hideSpinner();
  }
  setIsNeedReconnectFalse() {
    if (this._isOfflineMsgGot && this._isOfflinePresenceGot) {
      this._loggerService.debug("Both offline message and offline presence are received, set isNeedReconnect to false");
      this.realSetIsNeedReconnectFalse();
    }
  }
  setOfflineMsgGot() {
    this._loggerService.debug("Received offline message");
    this._isOfflineMsgGot = true;
    this.setIsNeedReconnectFalse();
  }
  setOfflinePresenceGot() {
    this._loggerService.debug("Received offline presencce");
    this._isOfflinePresenceGot = true;
    this.setIsNeedReconnectFalse();
  }

  setDisconnectTime() {
    let dt = this._lastActiveTime ? this._lastActiveTime : this._timestampService.getNowSecondString();
    this._loggerService.debug("Set disconnect time in cookies: " + dt);
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DISCONNECT_TIME, dt);
  }

}
